<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">
      {{ type == "create" ? "AGREGAR" : "EDITAR" }} AGENDAMIENTO
    </h1>
    <b-form ref="formAdd">
      <div class="row p-3">
        <div class="col-12 header-section">Usuario</div>
        <!-- Nombres -->
        <b-form-group id="nombres_usuario" label="Nombres:" label-for="nombres_usuario" class="col-md-3">
          <b-form-input id="nombres_usuario" v-model.trim="form.nombres_usuario" type="text" placeholder="Nombres"
            ref="nombres_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- apellidos -->
        <b-form-group id="apellidos_usuario" label="Apellidos:" label-for="apellidos_usuario" class="col-md-3">
          <b-form-input id="apellidos_usuario" v-model.trim="form.apellidos_usuario" type="text" placeholder="apellidos"
            ref="apellidos_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- email -->
        <b-form-group id="email_usuario" label="Email:" label-for="email_usuario" class="col-md-3">
          <b-form-input id="email_usuario" v-model.trim="form.email_usuario" type="text" placeholder="email"
            ref="email_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- telefono -->
        <b-form-group id="telefono_usuario" label="Teléfono:" label-for="telefono_usuario" class="col-md-3">
          <b-form-input id="telefono_usuario" v-model.trim="form.telefono_usuario" type="text" placeholder="telefono"
            ref="telefono_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Zona Horaria -->
        <b-form-group id="zona_horaria" label="Zona Horaria:" label-for="zona_horaria" class="col-md-3">
          <b-form-input id="zona_horaria" v-model.trim="form.zona_horaria" type="text" placeholder="Zona Horaria"
            ref="zona_horaria" :disabled="true"></b-form-input>
        </b-form-group>
        <div class="col-12 header-section">Canalizadora</div>
        <!-- Nombres Canalizadora -->
        <b-form-group id="nombre_sesionista" label="Nombres:" label-for="nombre_sesionista" class="col-md-3">
          <b-form-input id="nombre_sesionista" v-model.trim="form.nombre_sesionista" type="text" placeholder="Nombres"
            ref="nombre_sesionista" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Apellidos Canalizadora -->
        <b-form-group id="apellido_sesionista" label="Apellidos:" label-for="apellido_sesionista" class="col-md-3">
          <b-form-input id="apellido_sesionista" v-model.trim="form.apellido_sesionista" type="text"
            placeholder="Apellidos" ref="apellido_sesionista" :disabled="true"></b-form-input>
        </b-form-group>
        <div class="col-12 header-section">Sesión</div>
        <!-- tipo_sesion -->
        <b-form-group id="nombre_sesion" label="Tipo de sesión:" label-for="nombre_sesion" class="col-md-3">
          <b-form-input id="nombre_sesion" v-model.trim="form.nombre_sesion" type="text" placeholder="Tipo Sesión"
            ref="nombre_sesion" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Fecha y hora local del usuario -->
        <b-form-group id="fecha_hora_local" label="Fecha hora local del usuario:" label-for="fecha_hora_local"
          class="col-md-3">
          <b-form-input id="fecha_hora_local" :value="fecha_hora_local" type="text" placeholder="Fecha hora del usuario"
            ref="fecha_hora_local" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Fecha y hora local del canalizador -->
        <b-form-group id="fecha_hora_canalizador" label="Fecha hora del canalizador:" label-for="fecha_hora_canalizador"
          class="col-md-3">
          <b-form-input id="fecha_hora_canalizador" :value="fecha_hora_canalizador" type="text"
            placeholder="Fecha hora del canalizador" ref="fecha_hora_canalizador" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- comentarios -->
        <b-form-group id="comentario" label="Comentario:" label-for="comentario" class="col-md-3">
          <b-form-input id="comentario" v-model.trim="form.comentario" type="text" placeholder="Comentario"
            ref="comentario"></b-form-input>
        </b-form-group>
        <!-- locacion -->
        <b-form-group id="locacion" :label="`${$t('messages.location')}:`" label-for="locacion" class="col-md-3">
          <b-form-select id="location" v-model="form.locacion" :options="localizacionOpciones" style="width: 100%;"
            ref="locacion"></b-form-select>
        </b-form-group>

        <!-- Lugar de encuentro -->
        <b-form-group id="sitio_de_encuentro" label="Lugar de encuentro:" label-for="sitio_de_encuentro" class="col-md-3">
          <b-form-input id="sitio_de_encuentro" v-model.trim="form.sitio_de_encuentro" type="text"
            placeholder="sitio_de_encuentro" ref="sitio_de_encuentro"></b-form-input>
        </b-form-group>
        <!-- Estado -->
        <b-form-group id="estado" label="Estado:" label-for="input-3" class="col-md-3">
          <b-form-select id="estado" v-model="form.estado" :options="estados" ref="estado" :state="estadoState"
            required></b-form-select>
        </b-form-group>
      </div>
      <b-button variant="primary" v-b-modal.modal-update-date @click="loadEvents()">Cambiar fecha y hora</b-button>
      <b-button variant="primary" @click="handleSaveChanges" class="ml-3">Guardar cambios</b-button>
      <b-button variant="danger" class="ml-3" @click="$router.push({ name: 'AgendamientosAdmin' })">Cancelar</b-button>
      <b-modal id="modal-update-date" size="xl" title="Edición de fecha y hora" @ok="saveUpdateFechaHora">
        <div class="row">
          <div class="col-md-6 container-calendar">
            <DayPilotCalendar id="dp" :config="config" ref="calendar" />
          </div>
          <div class="col-md-6 container-hour">
            <div style="display: flex; justify-content: center">
              <b-calendar v-model="fechaSeleccionada" :locale="this.$i18n.locale" style="color: red"></b-calendar>
            </div>
            <div style="min-height: 100px; margin-top: 30px">
              <label for="selectorHoras" v-if="fechaSeleccionada">Hora del inicio de la sesión ({{ form.zona_horaria
              }})</label>
              <b-form-select id="selectorHoras" name="selectorHoras" v-model="horaSeleccionada"
                :options="horasDisponibles" v-if="fechaSeleccionada"></b-form-select>
              <label style="margin-top: 15px" v-if="hora_canalizador">{{
                `Hora de la canalizadora: ${hora_canalizador}`
              }}</label>
            </div>
          </div>
        </div>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
import SesionesService from "@/services/sesiones.service";
import EventBus from "@/services/eventBus.js";
import { Helpers } from "@/mixins/Helpers";
import { DayPilot, DayPilotCalendar } from "@daypilot/daypilot-lite-vue";
export default {
  name: "UserProcessAdmin",
  mixins: [Helpers],
  components: {
    DayPilotCalendar,
  },
  data() {
    return {
      sesionesService: null,
      estadoState: null,
      estados: [
        { text: "Propuesta", value: 1 },
        { text: "Aceptada", value: 2 },
        { text: "Rechazada", value: 3 },
        { text: "Cancelada", value: 4 },
        { text: "Finalizada", value: 5 },
      ],
      type: "edit",
      form: {
        id: 0,
        id_usuario: 0,
        id_sesionista: 0,
        dia: 0,
        mes: 0,
        anio: 0,
        hora: 0,
        id_tipo_sesion: 0,
        comentario: "",
        estado: 0,
        sitio_de_encuentro: "",
        zona_horaria: "",
        created: "",
        updated: "",
        status: 0,
        nombres_usuario: "",
        apellidos_usuario: "",
        email_usuario: "",
        telefono_usuario: "",
        nombre_sesionista: "",
        apellido_sesionista: "",
        nombre_sesion: "",
        descripcion: "",
        id_huso_horario: 0,
        duracion: 90,
        locacion:'online'
      },
      husoHorarios: [],
      fechaSeleccionada: null,
      horaSeleccionada: null,
      horasDisponibles: [],
      config: {
        startDate: DayPilot.Date.today(),
        timeRangeSelectedHandling: "Disabled",
        eventMoveHandling: "Disabled",
        eventResizeHandling: "Disabled",
        locale: "es-es",
        onBeforeEventRender: function (args) {
          args.data.cssClass = "myclass";
        }
      },
    };
  },
  created() {
    this.sesionesService = new SesionesService();
    this.$route.params.id
      ? (this.type = "edit")
      : this.$router.push({
        name: "Home",
      });
  },
  mounted() {
    this.getHusoHorarios();
    this.recoveryDataRegister();
    this.getHorasDisponibles();
  },
  methods: {
    loadEvents() {
      setTimeout(async () => {
        if (!this.$refs.calendar) return;
        this.calendar = this.$refs.calendar.control;
        const responde = await this.sesionesService.getAllAgendamientos();

        console.log(responde);

        const events = responde.data.data
          .filter((evento) => evento.estado === 1 || evento.estado === 2)
          .map((evento) => {
            const huso_horario = this.husoHorarios.find(
              (husoHorario) => husoHorario.id === this.form.id_huso_horario
            );

            const dateString = `${evento.anio}-${evento.mes < 10
              ? "0" + evento.mes.toString()
              : evento.mes.toString()
              }-${evento.dia - 1 < 10
                ? "0" + evento.dia.toString()
                : evento.dia.toString()
              } ${this.secondsToHM(evento.hora)}`;

            const local = this.$moment.tz(dateString, evento.zona_horaria);

            const a = local.clone().tz(huso_horario.timezone);

            const fechaHoraCanalizadoraInicio =
              a.format("YYYY-MM-DDTHH:mm") + ":00";

            const fechaHoraCanalizadoraFin =
              a.add(this.form.duracion, "minutes").format("YYYY-MM-DDTHH:mm") +
              ":00";

            return {
              id: evento.id,
              start: fechaHoraCanalizadoraInicio,
              end: fechaHoraCanalizadoraFin,
              text: `${evento.nombres_usuario} ${evento.apellidos_usuario} - ${evento.nombre_sesion}`,
              backColor: evento.estado === 1 ? "#fff600" : "#38761d",
              borderColor: "#38761d",
              fontColor: evento.estado === 2 ? "#ffffff" : "#000000",
            };
          });
        /* const events = [
          {
            id: 1,
            start: "2023-05-12T10:00:00",
            end: "2023-05-12T13:30:00",
            text: "Jose María Torres - Registros Akashicos",
            backColor: "#fff600",
            borderColor: "#38761d",
          },
          {
            id: 2,
            start: "2023-05-12T13:00:00",
            end: "2023-05-12T14:30:00",
            text: "Rosa Rivas - Registros Akashicos",
            backColor: "#6aa84f",
            borderColor: "#38761d",
            fontColor: "#ffffff",
          },
        ];
        console.log("paso 2"); */
        this.$refs.calendar.control.update({ events });
      }, 1000);
    },
    getHorasDisponibles() {
      this.horasDisponibles = [];
      const initialHour = 0;
      const finalHour = 81000;
      const interval = 600;
      for (let i = initialHour; i < finalHour; i += interval) {
        this.horasDisponibles.push({
          text: this.secondsToHM(i),
          value: i,
        });
      }
    },
    getHusoHorarios() {
      this.sesionesService
        .getHusoHorarios()
        .then((response) => {
          this.husoHorarios = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    recoveryDataRegister() {
      this.sesionesService
        .getAgendamientoByIdAdmin(this.$route.params.id)
        .then((result) => {
          if (!result.data.data) {
            this.$router.push({
              name: "Home",
            });
          }
          this.form = { ...result.data.data };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validation() {
      const validCampoSelect = this.$refs.estado.required
        ? this.$refs.estado.value
          ? true
          : false
        : true;
      this.estadoState = validCampoSelect;
      return validCampoSelect;
    },
    async handleSaveChanges() {
      try {
        const payload = {
          id_sesion: this.form.id,
          comentario: this.form.comentario,
          estado: this.form.estado,
          sitio_de_encuentro: this.form.sitio_de_encuentro,
          locacion: this.form.locacion,
        };
        const response = await this.sesionesService.updateAgendamiento(payload);
        if (response.data.status == 200) {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Felicidades",
            variant: "success",
            message: "Agendamiento actualizado satisfactoriamente",
          });
        } else {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Cita no agendada",
            variant: "danger",
            message: "No fue posible actualizar el agendamiento",
          });
        }
      } catch (error) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Cita no agendada",
          variant: "danger",
          message: "Ocurrio un error al actualizar el agendamiento",
        });
      }
    },
    async saveUpdateFechaHora() {
      try {
        const payload = {
          id_sesion: this.form.id,
          dia: this.fechaSeleccionada.split("-")[2],
          mes: this.fechaSeleccionada.split("-")[1],
          anio: this.fechaSeleccionada.split("-")[0],
          hora: this.horaSeleccionada,
        };
        const response = await this.sesionesService.updateFechaHoraAgendamiento(
          payload
        );
        if (response.data.status == 200) {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Felicidades",
            variant: "success",
            message: "Agendamiento actualizado satisfactoriamente",
          });
          this.recoveryDataRegister();
        } else {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Cita no agendada",
            variant: "danger",
            message: "No fue posible actualizar el agendamiento",
          });
        }
      } catch (error) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Cita no agendada",
          variant: "danger",
          message: "Ocurrio un error al actualizar el agendamiento",
        });
      }
    },
  },
  watch: {
    $route(to) {
      to.params.id ? (this.type = "edit") : (this.type = "create");
    },
    fechaSeleccionada(value) {
      this.config.startDate = value;
    },
  },
  computed: {
    fecha_hora_local() {
      if (!this.form.anio) return "";
      const dateString = `${this.form.anio}-${this.form.mes < 10
        ? "0" + this.form.mes.toString()
        : this.form.mes.toString()
        }-${this.form.dia < 10
          ? "0" + this.form.dia.toString()
          : this.form.dia.toString()
        } ${this.secondsToHM(this.form.hora)}`;

      const a = this.$moment.tz(dateString, this.form.zona_horaria);
      return a.format("dddd, DD-MM-YYYY HH:mm");
    },
    fecha_hora_canalizador() {
      if (
        !this.form.id_huso_horario ||
        !this.form.anio ||
        this.husoHorarios.length == 0 ||
        !this.form.zona_horaria
      )
        return "";

      const huso_horario = this.husoHorarios.find(
        (husoHorario) => husoHorario.id === this.form.id_huso_horario
      );

      const dateString = `${this.form.anio}-${this.form.mes < 10
        ? "0" + this.form.mes.toString()
        : this.form.mes.toString()
        }-${this.form.dia < 10
          ? "0" + this.form.dia.toString()
          : this.form.dia.toString()
        } ${this.secondsToHM(this.form.hora)}`;

      const local = this.$moment.tz(dateString, this.form.zona_horaria);

      const a = local.clone().tz(huso_horario.timezone);

      return a.format("dddd, DD-MM-YYYY HH:mm");
    },
    hora_canalizador() {
      if (
        !this.form.id_huso_horario ||
        this.husoHorarios.length == 0 ||
        !this.fechaSeleccionada ||
        !this.horaSeleccionada
      )
        return "";

      const huso_horario = this.husoHorarios.find(
        (husoHorario) => husoHorario.id === this.form.id_huso_horario
      );

      const local = this.$moment.tz(
        `${this.fechaSeleccionada} ${this.secondsToHM(this.horaSeleccionada)}`,
        this.form.zona_horaria
      );

      const a = local.clone().tz(huso_horario.timezone);
      return a.format("HH:mm");
    },
    localizacionOpciones() {
      return [
        { value: 'inperson', text: this.$t("messages.inperson") },
        { value: 'online', text: this.$t("messages.online") }
      ]
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding-bottom: 30px;
}

.header-section {
  text-align: left;
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 1.2rem;

  &:not(:first-child) {
    margin-top: 30px;
  }
}

.myclass {
  font-size: 80px;
  font-weight: bold;
}

.calendar_default_event_inner {
  background: #2e78d6;
  color: white;
  border-radius: 5px;
  opacity: 0.9;
}

@media screen and (max-width: 1500px) {
  .header-section {
    margin-bottom: 15px;
    font-size: 1rem;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .mb-5 {
    margin-bottom: 0px !important;
  }
}
</style>
